// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$LI-angular-primary: mat.define-palette(mat.$light-blue-palette, 700);
$LI-angular-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$LI-angular-warn: mat.define-palette(mat.$red-palette);

/* You can add global styles to this file, and also import other style files */
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config($font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline-1: mat.define-typography-level(15px, 25px),
);

@include mat.core-typography($custom-typography);

// Create the theme object (a Sass map containing all of the palettes).
$LI-angular-theme: mat.define-light-theme((
  color: (
    primary: $LI-angular-primary,
    accent: $LI-angular-accent,
    warn: $LI-angular-warn,
  ),
  typography: $custom-typography,
  density: -1,
));

$primary: mat.get-color-from-palette($LI-angular-primary);
$accent: mat.get-color-from-palette($LI-angular-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($LI-angular-theme);

//Dot Colors
$red-dot: #EE0D0D;
$yellow-dot: #FFC600;
$green-dot: #84BD00;

// Define variables here
$yellow-orange: #FFAB40;
$safety-orange: #FA6400;
$shade-of-gray: #959595;
$link-water: #D5D6D7;
$model-refinement-button-color: $yellow-orange;
$model-refinement-label-color: $shade-of-gray;
$model-refinement-select-border-color: $link-water;

// Pill Colors
$pill-orange: rgba(255, 224, 133);
$pill-orange-secondary: rgba(255, 235, 183);
$pill-yellow: rgba(234, 232, 110);
$pill-yellow-secondary: rgba(255, 252, 183);
$pill-chartreuse: rgba(208, 238, 130);
$pill-chartreuse-secondary: rgba(236, 255, 183);
$pill-green: rgba(177, 244, 157);
$pill-green-secondary: rgba(202, 255, 183);
$pill-teal: rgba(136, 248, 200);
$pill-teal-secondary: rgba(183, 255, 220);
$pill-blue: rgba(111, 246, 253);
$pill-blue-secondary: rgba(193, 252, 255);
$pill-lilac: rgba(221, 225, 255);
$pill-lilac-secondary: rgba(197, 206, 255);
$pill-pink: rgba(251, 215, 255);
$pill-pink-secondary: rgba(248, 204, 255);

// Old pill colors
$pill-old-orange: orange;
$pill-old-orange-secondary: rgba(255, 165, 0, 0.3);
$pill-old-thistle: thistle;
$pill-old-thistle-secondary: rgba(216, 191, 216, 0.5);
$pill-old-green: rgba(0, 209, 178);
$pill-old-green-secondary: rgba(0, 209, 178, 0.5);

// Pill categories
$machine-label: $pill-old-orange;
$machine-inactive-label: $pill-old-orange-secondary;
$machine-extractor-label: $pill-old-thistle;
$machine-extractor-inactive-label: $pill-old-thistle-secondary;
$user-label: $pill-old-green;
$user-inactive-label: $pill-old-green-secondary;
$gpt-extractor-label: $pill-blue;

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
::ng-deep .mat-button-toggle-checked {
  background-color: $primary;
  color: white;
  --mat-standard-button-toggle-selected-state-background-color: #0288d1;
  --mat-standard-button-toggle-selected-state-text-color: white;
}

html,
body {
  height: 100%;
  overflow: hidden !important;
}

body {
  margin: 0;
  overflow: hidden;
}

.mat-mdc-menu-panel {
  max-width: 500px !important;
  min-height: auto !important;
}

.ng2-dropdown-menu {
  width: 50vh !important;
}

.mt-15 {
  margin-top: 20px;
}

.mat-mdc-chip {
  font-weight: 400;
  cursor: pointer;
}

.dash_content {
  background-color: #f3f3f4 !important;
}

.mat-mdc-list {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.test {
  white-space: pre-line;
  font-size: 15px;
}

.machine-highlight {
  background-color: rgba($machine-inactive-label, 0.63);
}

.machine-extractor-highlight {
  background-color: rgba($machine-extractor-inactive-label, 0.63);
}

.gpt-extractor-highlight {
  background-color: rgba($gpt-extractor-label, 0.63);
}

.user-highlight {
  background-color: rgba($user-inactive-label, 0.95);
}

.mat-mdc-snack-bar-container {
  &.blue-snackbar {
    --mdc-snackbar-container-color: #2196f3;
    --mdc-snackbar-supporting-text-color: white;
  }
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: green;
    --mdc-snackbar-supporting-text-color: white;
  }
}

.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: #b00020;
    --mdc-snackbar-supporting-text-color: white;
  }
}

.spinner-message {
  text-align: center;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: white;
}

#toast-container>div {
  opacity: 1;
}

.mat-mdc-chip :hover {
  background: transparent !important;
  opacity: 1 !important;
}

.mat-toolbar-single-row {
  height: auto !important;
}

.mat-mdc-card {
  padding: 10px !important;
}

.scroll-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 71px);
}

.mat-mdc-raised-button {
  line-height: 15px !important;
  padding: 0 7px !important;
  font-size: 12px !important;
}

.mat-h2,
.mat-headline-6,
.mat-typography h2 {
  font: 600 15px/18px Roboto, 'arial', sans-serif;
  margin: 0;
}

.mat-h3,
.mat-typography h3 {
  font: 500 13px Roboto, 'arial', sans-serif;
  margin-bottom: 5px;
}

.mat-typography p {
  font: 400 13px/20px Roboto, 'arial', sans-serif;
  margin: 1px;
}

.mat-typography {
  font: 400 12px Roboto, 'arial', sans-serif;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
::ng-deep .mat-button-toggle-label-content {
  line-height: 27px !important;
}

.mat-mdc-menu-item {
  font-size: 13px;
  height: 35px !important;
  line-height: 35px !important;
  padding: 0 11px;
}

.mat-mdc-menu-item .mat-icon {
  font-size: 17px;
  vertical-align: sub !important;
  margin-right: 0 !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 53px;
  padding: 0 8px;
}

.mat-toolbar-multiple-rows {
  min-height: auto;
}

tr.mat-mdc-footer-row,
tr.mat-mdc-row,
.mat-mdc-header-row {
  height: 35px;
}

.mat-mdc-cell {
  font-size: 12px !important;
}

.paginator {
  height: 35px;
}

.mat-mdc-paginator-container {
  min-height: 35px !important;
}

h2 {
  font-size: 13px;
  font-weight: 500;
}

.title-icon {
  height: auto !important;
  width: auto !important;
  line-height: unset !important;
}

.mat-expansion-panel-header {
  padding: 0 10px !important;
}

/* TODO(UX-Review): Aks UX team if the default size of the pills is correct. */
// The new version of angular makes things bigger, so this is no longer necessary.
.mat-mdc-standard-chip {
  //min-height: 27px !important;
  //padding: 5px 10px 5px 10px !important;
  //font-size: 12px !important;
  //font-weight: 400 !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

.modal-gray .mat-mdc-dialog-container {
  background: #D9D9D9 !important;
}

.modal-default .mat-mdc-dialog-container {
  background: #ffffff;
}
